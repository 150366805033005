import { Model } from "./Model";
import { modules } from "./../../main";
import jQuery = require( "jquery" );

import Handlebars = require( "handlebars" );

export class View {

    private name:string;
    private template:( data:string ) => any;

    public constructor ( name:string, template:string ){
        this.name = name;
        
        try {
            this.template = Handlebars.compile( template );
        } catch {
            throw new Error("Das folgende Template hat einen Fehler: " + this.name);
        }    
        
    }

    public process ( data:Object, debug:boolean = false ):string {
        try {
            var result:string = this.template( data as any );

            if ( result != "" ){
                result = ( debug ? "<!--" + this.name + "-->": "" ) + result;
            }

            return result;
        } catch ( e ){
            modules.error( "Beim verarbeiten des folgenden Templates ist ein Fehler aufgetreten: " + this.name );

            return null;
        }     
    }

    public getName ():string{
        return this.name;
    }

    public geTemplate ():( data:string ) => any{
        return this.template;
    }

}