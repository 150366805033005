import { ControllerMenu } from "./ControllerMenu";
import { ModelMenu } from "./ModelMenu";
import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import jQuery = require( "jquery" );
import { modules } from "../../main";
import { ControllerNavigationSync } from "../NavigationSync/ControllerNavigationSync";

export class ModuleMenu extends HTMLModule<ModelMenu>{
        
    public constructor ( configuration:Object ){
        super( "Menu", configuration );

        this.registerDependentModule( "Menu", ControllerMenu.prototype );
        this.registerDependentModule( "NavigationSync", ControllerNavigationSync.prototype );

        this.initAll( ControllerMenu.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
    }

}