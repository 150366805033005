import { ModelFormValidator } from "./ModelFormValidator";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { FormItem } from "./FormItem";
import { modules } from "./../../main";
import { Pair } from "./../../libs/Pair";
import { Windows } from "./../../libs/Windows";
import jQuery = require( "jquery" );
import { HTMLModule } from "../../classes/mvc/HTML/HTMLModule";
import { Globals } from "../../classes/Globals";
import { Validation } from "./Validation";
import { Arrays } from "../../libs/Arrays";
import { Elements } from "../../libs/Elements";

enum ShowStatusType{
    ALL = "all",
    ONLY_VALID_INPUTS = "valid"
}

export class ControllerFormValidator extends HTMLController<ModelFormValidator>{

    private formItems:Array<FormItem>;
    private submit:JQuery<HTMLElement>;

    private functionCallbacks:Array<Pair<string, Validation>>;

    private lastInputValue:string;

    private showStatusImmediatelyType:string;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>){
        super( new ModelFormValidator(), accessName, accessID, element );
    }

    public initGlobals ():void{
    }

    public run ():void{
        this.formItems = new Array();
        this.submit = this.getItem( "submit" );

        this.functionCallbacks = new Array();

        this.lastInputValue = "";
        this.showStatusImmediatelyType = "";

        var items:Array<JQuery<HTMLElement>> = this.getItems( "item" );
        for (let i = 0; i < items.length; i++) {
            
            var itemElement:JQuery<HTMLElement> = items[i];
            if ( itemElement != null && itemElement.length ){
                
                var formItem:FormItem = new FormItem( itemElement );
                if ( formItem.isItemValid() ){
                    this.formItems.push( formItem );
                }

            }

        }

        if ( this.submit.length && this.formItems != null && this.formItems.length > 0 ){
            var showStatusImmediately:string = this.getParam( "show-status-immediately" );
            if ( showStatusImmediately != null ){
                this.showStatusImmediatelyType = showStatusImmediately;
            }

            for (let i = 0; i < this.formItems.length; i++) {
                /**********************************/
                /********** Module Start **********/
                /**********************************/

                this.validate( this.formItems[i], function ( formItem:FormItem ){
                     /**
                     * 
                     * Show the item status immediately after the module starts
                     * This is the callback function called
                     */
                    this.showItemStatusImmediately( formItem );
                }.bind(this));

                /**
                 * 
                 * Show the item status immediately after the module starts
                 */
                this.showItemStatusImmediately( this.formItems[i] );

                /****************************/
                /********** Events **********/
                /****************************/

                var callback = function ( formItem:FormItem ){
                    this.setItemStatus( formItem );
                };

                /**
                 * 
                 * Bind all validating events onInput or onChange
                 */
                if ( Elements.isSelectBox( this.formItems[i].getInputElement() ) || Elements.isRadioBox( this.formItems[i].getInputElement() ) || Elements.isCheckBox( this.formItems[i].getInputElement() ) ){
                    jQuery( this.formItems[i].getInputElement() ).change( this.validate.bind( this, this.formItems[i], callback ) );
                } else {
                    jQuery( this.formItems[i].getInputElement() ).on( "input", this.validate.bind( this, this.formItems[i], callback ) );
                }

                /**
                 * 
                 * Bind special event on the last item to show the status of the last element immediately if all other inputs are valid
                 */
                if ( i == this.formItems.length - 1 ){
                    if ( Elements.isSelectBox( this.formItems[i].getInputElement() ) || Elements.isRadioBox( this.formItems[i].getInputElement() ) || Elements.isCheckBox( this.formItems[i].getInputElement() ) ){
                        jQuery( this.formItems[i].getInputElement() ).change( this.lastItemCheck.bind( this, this.formItems[i] ) );
                    } else {
                        jQuery( this.formItems[i].getInputElement() ).on( "input", this.lastItemCheck.bind( this, this.formItems[i] ) );
                    }
                }

                /**
                 * 
                 * Bind the on loosing focus event to display the status on loosing focus
                 */
                jQuery( this.formItems[i].getInputElement() ).on( "focusout", this.setItemStatus.bind( this, this.formItems[i] ) );
            }


            if ( this.getElement().is( "form" ) ){
                this.getElement().on( "submit", function ( event:Event ){

                    var validCount = 0;

                    for (let i = 0; i < this.formItems.length; i++) {
                        if ( this.formItems[i].isInputValid() ){
                            this.formItems[i].getItemElement().attr( Globals.ATTRIBUTE_PREFIX + "valid", "true" );

                            validCount++;
                        } else {
                            this.formItems[i].getItemElement().attr( Globals.ATTRIBUTE_PREFIX + "valid", "false" );
                        }
                    }

                    if ( validCount < this.formItems.length ){
                        event.preventDefault();
                    }

                }.bind(this));
            }
        } else {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " keine Items oder kein Submit-Button gefunden wurde." );
        }
    }

    private validate( item:FormItem, callback:( item:FormItem ) => void = function ( item:FormItem ){} ):void {
        var currentItemValid:boolean = true;

        var inputValue:string = item.getValue();

        if ( inputValue == item.getDefaultValue() ){
            inputValue = "";
        }

        var itemElement:JQuery<HTMLElement> = item.getItemElement();

        /**
         * 
         * Get all levels (sorted)
         */
        var levels:Array<number> = item.getLevels();

        for (let i = 0; i < levels.length; i++) {
            var levelValid = true;
            var validations:Array<Validation> = item.getLevel( levels[i] );

            if ( validations.length ){
                for (let j = 0; j < validations.length; j++) {
                    if ( !validations[j].isValidationValid() || inputValue != this.lastInputValue ){
                        var currentValidationValid:boolean = true;
            
                        var validationKey:string = validations[j].getKey();
                        var validationValue:string = validations[j].getValue();
            
                        switch ( validationKey ){
                            case "required":
                                currentValidationValid = inputValue.length > 0;
                            break;
            
                            case "regex":
                                var regex:RegExp = new RegExp( validationValue );
                                if( typeof( regex ) !== "undefined" && regex !== null ){
                                    currentValidationValid = regex.test( inputValue );
                                } else {
                                    currentValidationValid = false;
                                }
                            break;
            
                            case "module":
                                currentValidationValid = modules.callMethod( validationValue, "isInputValid", HTMLModule.findControllerID( itemElement, validationValue ) );
                            break;
            
                            case "function":
                                /**
                                 * 
                                 * Set the default to false
                                 */
                                currentValidationValid = false;
                                
                                if ( Windows.globalFunctionExsists( validationValue ) ){
                                    var callbackID:string = Math.random().toString(32).substr(2);

                                    var functionCallback:( callbackID:string, valid:boolean ) => void = function ( callbackID:string, valid:boolean ){

                                        for (let i = 0; i < this.functionCallbacks.length; i++) {
                                            if ( this.functionCallbacks[i].getKey() == callbackID ){
                                                this.functionCallbacks[i].getValue().setValidationValid( valid );
                                                
                                                if ( valid ){
                                                    this.validate( item );
                                                }

                                                try{
                                                    callback.call( this, item );
                                                } catch ( e ){

                                                }
                                                
                                                Arrays.removeByIndex( this.functionCallbacks, i );

                                                break;
                                            }
                                        }

                                    };

                                    this.functionCallbacks.push( new Pair( callbackID, validations[j] ) );

                                    currentValidationValid = Windows.callGlobalFunctionWithCaller( validationValue, false, this, inputValue, functionCallback, callbackID );
                                }
                            break;
            
                            default:
                                var view:string = this.getModule().getConfig( "validations." + validationKey );
                                if ( view != null ){
                                    var viewName:string = validationKey;
                                    var modelID = this.getModel().new();
                                    
                                    this.getModule().addView( viewName, view );
                                    
                                    this.getModel().add( modelID, "value", inputValue );
                                    this.getModel().add( modelID, "param", validationValue );
                                    this.getModel().add( modelID, "lang_code", modules.getLanguageCode() );
                                    this.getModel().add( modelID, "lang_id", modules.getLanguageID() );
            
                                    currentValidationValid = this.process( modelID, viewName ) == "true";
                                }
                        }
                        
                        if ( !currentValidationValid ){
                            levelValid = false;
                        }

                        validations[j].setValidationValid( currentValidationValid );
                    }
                }

                if ( !levelValid ){
                    currentItemValid = false;

                    break;
                }
            }
        }
        
        this.lastInputValue = inputValue;
        item.setInputValid( currentItemValid );
    }

    private showItemStatusImmediately( formItem:FormItem ){
        if ( this.showStatusImmediatelyType != null ){
            switch ( this.showStatusImmediatelyType ){
                case ShowStatusType.ALL:
                    this.setItemStatus( formItem );
                break;

                case ShowStatusType.ONLY_VALID_INPUTS:
                    if ( formItem.isInputValid() ){
                        this.setValid( formItem.getItemElement() );
                    }
                break;
            }
        }
    }

    private setValid ( element:JQuery<HTMLElement> ):void{
        element.attr( Globals.ATTRIBUTE_PREFIX + "valid", "true" );
    }

    private setInvalid ( element:JQuery<HTMLElement> ):void{
        element.attr( Globals.ATTRIBUTE_PREFIX + "valid", "false" );
    }

    private setItemStatus ( formItem:FormItem ):void{
        if ( formItem.isInputValid() ){
            this.setValid( formItem.getItemElement() );
        } else {
            this.setInvalid( formItem.getItemElement() );
        }
    }

    private lastItemCheck ( formItem:FormItem ):void{
        var validCount = 0;

        for (let i = 0; i < this.formItems.length; i++) {
            if ( this.formItems[i].isInputValid() ){
                validCount++;
            }
        }

        if ( validCount == this.formItems.length ){
            formItem.getItemElement().attr( Globals.ATTRIBUTE_PREFIX + "valid", "true" );
        } else {
            if ( !formItem.isInputValid() ){
                formItem.getItemElement().removeAttr( Globals.ATTRIBUTE_PREFIX + "valid" );
            }
        }
    }
}