import { Strings } from "./Strings";

import jQuery = require( "jquery" );
import yaml = require( "js-yaml" );

export class AjaxRequest {

    public static getJsObject ( url:string, data:Object = {} ):Object{
        let result:Object = null;

        if ( Strings.endsWith( url, ".json" ) ){
           result = this.getJson( url, data );
        } else if ( Strings.endsWith( url, ".yaml" ) ){
           result = this.getYaml( url, data );
        }

        return result;
    }

    public static getJson ( url:string, data:Object = {} ):Object{
        let result:Object = null;

        jQuery.ajax({
            url: url,
            dataType: "json",
            async: false,
            data: data,
            success: function ( response ){
                if ( response != null ) {
                    result = response;
                }
            }
        });

        return result;
    }

    public static getYaml ( url:string, data:Object = {} ):Object{
        let result:Object = null;

        jQuery.ajax({
            url: url,
            async: false,
            data: data,
            success: function ( response ){
                if ( response != null ) {
                    try {
                        result = yaml.load( response );
                    } catch {
                        result = null;
                    }
                }
            }
        });

        return result;
    }

    public static getXML ( url:string, data:Object = {} ):Document{
        let result:Document = null;

        jQuery.ajax({
            url: url,
            async: false,
            data: data,
            success: function ( response ){
                if ( response != null ) {
                    try {
                        result = new DOMParser().parseFromString( response, "text/xml" );
                    } catch {
                        result = null;
                    } 
                }
            }
        });

        return result;
    }
}