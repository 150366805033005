import jQuery = require( "jquery" );

export class OrderByItem {

    private ID:number;
    private LABEL:string;

    public constructor ( id:number, label:string ){
        this.ID = id;
        this.LABEL = label;
    }

    public getID():number {
        return this.ID;   
    }

    public getLabel ():string{
        return this.LABEL;
    }

}