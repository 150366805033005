import { Model } from "./../../classes/mvc/Model";
import { Module } from "./../../classes/mvc/Module";
import { AjaxRequest } from "./../../libs/AjaxRequest";
import jQuery = require( "jquery" );

export class ModelNavigationSync extends Model{

    public constructor (){
        super();
    }

    public getSearchResults ( url:string ):Object{
		return AjaxRequest.getJson( url );
	}
    
}