import { Model } from "./../../classes/mvc/Model";
import { Module } from "./../../classes/mvc/Module";
import jQuery = require( "jquery" );

export class ModelCookieDirective extends Model{

    public constructor (){
        super();
    }
    
}