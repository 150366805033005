import { Pair } from "./../../libs/Pair";
import { Globals } from "./../../classes/Globals";
import { Strings } from "../../libs/Strings";
import { Item } from "../../libs/Item";
import { Validation } from "./Validation";
import { Elements } from "../../libs/Elements";

export class FormItem extends Item{

    private itemElement:JQuery<HTMLElement>;
    private inputElement:JQuery<HTMLElement>;

    private validations:Array<Validation>;

    private defaultValue:string;

    private inputValid:boolean;

    public constructor ( itemElement:JQuery<HTMLElement> ){
        super();

        this.itemElement = itemElement;
        this.inputElement = this.itemElement.find( "["+ Globals.ATTRIBUTE_PREFIX +"input]" );
        this.validations = new Array();

        this.defaultValue = "";

        this.inputValid = false;

        if ( this.itemElement.length > 0 && this.inputElement.length > 0 ){
            var validations:Array<Validation> = new Array();

            jQuery.each(this.itemElement[0].attributes, function() {
                if ( Strings.startsWith( this.name, Globals.ATTRIBUTE_PREFIX + "validation-" ) ){
                    var key:string = this.name.replace( Globals.ATTRIBUTE_PREFIX + "validation-", "" );
                    var value:string  = this.value;

                    validations.push( new Validation( key, value ) );
                }
            });
    
            this.validations = validations;
    
            var defaultValue = this.inputElement.attr( Globals.ATTRIBUTE_PREFIX +"default" );
            if ( defaultValue !== undefined ){
                this.defaultValue = defaultValue;
            }
        }
       
        this.setValid( this.itemElement.length > 0 && this.inputElement.length > 0 && this.validations.length > 0 );
    }

    public getItemElement ():JQuery<HTMLElement>{
        return this.itemElement;
    }

    public getInputElement ():JQuery<HTMLElement>{
        return this.inputElement;
    }

    public getDefaultValue ():string{
        return this.defaultValue;
    }

    public isInputValid ():boolean{
        return this.inputValid;
    }

    public setInputValid ( valid:boolean ):void{
        this.inputValid = valid;
    }

    public getValue ():string{
        if ( Elements.isCheckBox( this.inputElement ) ){
            return this.inputElement.is( ":checked" ) ? String( this.inputElement.val() ) : "";
        } else if ( Elements.isRadioBox( this.inputElement ) ){
            var groupName = this.inputElement.attr( "name" );
            var selected = jQuery( "input[type='radio'][name='"+ groupName +"']:checked" );
            if ( selected.length ){
                return String( selected.val() );
            } else {
                return "";
            }
        } else {
            return String( this.inputElement.val() );
        }
    }

    public getLevels ():Array<number>{
        var result:Array<number> = new Array();

        for (let i = 0; i < this.validations.length; i++) {
            if ( result.indexOf( this.validations[i].getLevel() ) == -1 ){
                result.push( this.validations[i].getLevel() );
            }
        }

        return result.sort();
    }

    public getLevel ( level:number ):Array<Validation>{
        var result:Array<Validation> = new Array();

        for (let i = 0; i < this.validations.length; i++) {
            if ( this.validations[i].getLevel() == level ){
                result.push( this.validations[i] );
            }
        }

        return result;
    }

}