import { Model } from "./../../classes/mvc//Model";
import { Module } from "./../../classes/mvc//Module";
import jQuery = require( "jquery" );

export class ModelCallNumber extends Model{

    public constructor (){
        super();
    }
    
}