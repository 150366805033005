import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerPasswordToggle } from "./ControllerPasswordToggle";
import { ModelPasswordToggle } from "./ModelPasswordToggle";
import jQuery = require( "jquery" );
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "PasswordToggle",
    style: "password-toggle.scss"
})

export class ModulePasswordToggle extends HTMLModule<ModelPasswordToggle> {

    public constructor ( configuration:Object ){
        super( "PasswordToggle", configuration );

        this.initAll( ControllerPasswordToggle.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}