import { ModelCloudPictures } from "./ModelCloudPictures";
import { Controller } from "../../classes/mvc/Controller";
import { Json } from "../../libs/Json";

export class ControllerCloudPictures extends Controller<ModelCloudPictures>{

    private static OUTPUT_IMAGE_CONTAINER:string = "#image_container";
    private static OUTPUT_VISIBLE_THUMBS:string = ".visible-thumbnail";
    private static OUTPUT_INVISIBLE_THUMBS:string = ".invisible-thumbnail";
    private static OUTPUT_IMAGE_ELEMENT:string = "#main-picture";

    public constructor(accesName:string, accessID:number) {
        super(new ModelCloudPictures(), accesName, accessID);
    }

    public initGlobals():void {

    }

    public run():void {
        // var data:object = this.getModule().getData();
        // var smallPicture:string = Json.getSubobject(data, "A");
        // var bigPicture:string = Json.getSubobject(data, "B");
        // var thumbnails:Array<string> = Json.getSubobject(data, "C");

        // // console.log(smallPicture);
        // // console.log(bigPicture);
        // // console.log(thumbnails);

        // // var mainPictureEl:JQuery<HTMLElement>  = jQuery(ControllerCloudPictures.OUTPUT_IMAGE_ELEMENT);
        // // if(mainPictureEl !== null) {
        // //     jQuery(mainPictureEl).attr("src", bigPicture)
        // // }
    }
}