import { Module } from "./../../classes/mvc/Module";
import { ControllerRegister } from "./ControllerRegister";
import { modules } from "./../../main";
import { ModelRegister } from "./ModelRegister";
import jQuery = require( "jquery" );

export class ModuleRegister extends Module<ModelRegister> {

    public constructor ( configuration:Object ){
        super( "Register", configuration );
        
        this.addController( new ControllerRegister( this.getName(), this.getAccessID() ) );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}