import jQuery = require( "jquery" );

export class Checker {

    private valid:boolean;
    private errorCode:number;

    public constructor (errorCode:number){
        this.valid = errorCode == 200;
        this.errorCode = errorCode;
    }

    public isValid ():boolean{
        return this.valid;
    }

    public getErrorCode ():number{
        return this.errorCode;
    }
}