import { Model } from "./../../classes/mvc/Model";
import { Module } from "./../../classes/mvc/Module";
import { AjaxRequest } from "./../../libs/AjaxRequest";
import { modules } from "./../../main";
import jQuery = require( "jquery" );

export class ModelMenu extends Model{

    public getMenuFile ( menuFileBasePath:string ):Object{
		var result = AjaxRequest.getJson( menuFileBasePath + modules.getLanguageID() + ".json" );
		
		if ( result == null ){
			result = AjaxRequest.getJson( menuFileBasePath + modules.getLanguageCode() + ".json" );
		}

		return result;
    }
    
}