import { ModelFilter } from "./ModelFilter";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import jQuery = require("jquery");
import { Strings } from "../../libs/Strings";
import { Pair } from "../../libs/Pair";
import { Globals } from "../../classes/Globals";
import { FilterItem } from "./FilterItem";
import { Option } from "./Option";
import { Category } from "./Catgeroy";
import { modules } from "../../main";
import { Windows } from "../../libs/Windows";
import { Arrays } from "../../libs/Arrays";

enum InitMode {
	HTML = "artnr",
	JSON = "json"
}

export class ControllerFilter extends HTMLController<ModelFilter> {

	private static OUTPUT_ITEM: string = "<span></span>";
	private static OUTPUT_ESCAPING: Array<string> = new Array();
	private static OUTPUT_ESCAPING_WITH: string = "<br>";
	private static OUTPUT_CUT_KEYS: Array<string> = new Array();

	private formatSpacer: string;
	private formatPositions: Array<any>;

	private categories: Array<Category>;
	private items: Array<FilterItem>;

	private submit: JQuery<HTMLElement>;

	private canClickDisabled: boolean;

	public constructor(accessName: string, accessID: number, element: JQuery<HTMLElement>) {
		super(new ModelFilter(), accessName, accessID, element);

		this.formatSpacer = "-";
		this.formatPositions = new Array();
	}

	public initGlobals(): void {
		var output = this.getModule().getComponent("output-item");
		if (output != null) {
			ControllerFilter.OUTPUT_ITEM = output;
		}

		var outputEscaping: Array<string> = this.getModule().getConfig("output_escaping");
		if (outputEscaping != null && outputEscaping.length) {
			ControllerFilter.OUTPUT_ESCAPING = outputEscaping;
		}

		var outputEscapingWith: string = this.getModule().getConfig("output_escape_with");
		if (outputEscapingWith != null) {
			ControllerFilter.OUTPUT_ESCAPING_WITH = outputEscapingWith;
		}

		var outputCutKeys: Array<string> = this.getModule().getConfig("output_cut_keys");
		if (outputCutKeys != null && outputCutKeys.length) {
			ControllerFilter.OUTPUT_CUT_KEYS = outputCutKeys;
		}

		this.canClickDisabled = this.getParam("can-click-disabled") != "false";
	}

	public run(): void {
		this.categories = new Array();
		this.items = new Array();

		/**
		 * 
		 * Format stuff
		 */
		var formatSpacer = this.getParam("format-spacer");
		var formatPositions = this.getParam("format-pos");

		if (formatSpacer != null) {
			this.formatSpacer = formatSpacer;
		}

		if (formatPositions != null) {
			var positions = Strings.toArray(formatPositions);
			for (let i = 0; i < positions.length; i++) {
				this.formatPositions.push(positions[i]);
			}
		}

		/**
		* 
		* Get all items
		*/


		/**
		* 
		* 
		*/
		var initMode: InitMode = InitMode.HTML;
		var initModeParam: string = this.getParam("init-mode");
		var data = this.getModule().getData();

		if (data != null && initModeParam == InitMode.JSON) {
			initMode = InitMode.JSON;
		}

		if (initMode == InitMode.HTML) {

			var items = this.getItems("filter");
			for (let i = 0; i < items.length; i++) {

				var number: string = items[i].attr(Globals.ATTRIBUTE_PREFIX + "format");

				if (typeof number !== "undefined") {
					var splitted = jQuery.trim(number).split(this.formatSpacer);
					this.items.push(FilterItem.initWithArticleNumber(splitted, items[i]));
				}
			}

		} else if (initMode == InitMode.JSON) {
			var objectItems: Array<any> = this.getModule().getData();
			for (let i = 0; i < objectItems.length; i++) {
				this.items.push(FilterItem.initWithObject(objectItems[i], this.formatPositions));
			}
		}

		/**
		* 
		* Get HTML Elements
		*/
		var submitButton = this.getItem("submit");
		if (submitButton.length) {
			this.submit = submitButton;
		}

		/**
		* 
		* Run
		*/
		if (this.formatSpacer != "" && this.formatPositions.length > 0) {

			if (this.items.length > 0) {

				this.loadCategories();

				if (this.categories.length > 0) {

					this.orderCategoryOptions();
					this.buildCategoryOptions();
					this.removesUnelectableItems();
					this.assignEvents();
					this.processAll();
					this.show();

				} else {
					this.getModule().error(Globals.MODULE_LOADING_ERROR + " zu wenige Kategorien angegeben wurden oder diese ein falsches Format haben");
				}

			} else {
				this.getModule().error(Globals.MODULE_LOADING_ERROR + " zu wenige Items gefunden wurden");
			}

		} else {
			this.getModule().error(Globals.MODULE_LOADING_ERROR + " der Parameter format-spacer nicht oder falsch angegeben wurde");
		}
	}

	/**
	* 
	* GUI Stuff
	*/
	private assignEvents(): void {
		for (let i = 0; i < this.categories.length; i++) {
			for (let j = 0; j < this.categories[i].getOptions().length; j++) {
				this.categories[i].getOptions()[j].getElement().on("click", function (category: Category, option: Option) {

					if (!(this.categories[i].getOptions()[j].isDisabled() && !this.canClickDisabled)) {
						category.deactivateAllOptions();
						option.activate();
						category.changed();

						this.processAll(category.getKey());

						this.show();
					}

				}.bind(this, this.categories[i], this.categories[i].getOptions()[j]));
			}
		}
	}

	private show(): void {
		for (let i = 0; i < this.categories.length; i++) {
			for (let j = 0; j < this.categories[i].getOptions().length; j++) {
				this.categories[i].getOptions()[j].show();
			}
		}
	}

	/**
	* 
	* Check selected
	*/
	private selected(selectionNeeded: boolean): Array<FilterItem> {
		var result: Array<FilterItem> = new Array();
		var allCategoriesSelected: boolean = true;

		var selectedCombination: any = {};

		for (let i = 0; i < this.categories.length; i++) {
			var selectedOne = this.categories[i].getSelected();
			if (selectedOne != null) {
				selectedCombination[this.categories[i].getKey()] = selectedOne.getKey();
			} else {
				allCategoriesSelected = false;
			}
		}

		var selectedCombinationKeys = Object.keys(selectedCombination);

		if (allCategoriesSelected || !selectionNeeded) {
			var item: FilterItem = null;

			for (let i = 0; i < this.items.length; i++) {
				var found: boolean = true;


				for (let j = 0; j < selectedCombinationKeys.length; j++) {
					var selectedCombinationKey = selectedCombinationKeys[j];
					var selectedCombinationValue = selectedCombination[selectedCombinationKey]
					if (this.items[i].getCombinationValue(selectedCombinationKey) == selectedCombinationValue) {
						item = this.items[i];
					} else if (typeof selectedCombinationValue !== "undefined") {
						found = false;
					}
				}

				if (found && item != null) {
					result.push(item);

					break;
				}
			}

		}

		return result;
	}

	private processAll(changedCategoryPosition: number = -1) {
		if (this.categories.length > 1) {
			for (let i = 0; i < this.categories.length; i++) {

				var selectedOption = this.categories[i].getSelected();
				if (selectedOption != null) {

					for (let j = 0; j < this.categories.length; j++) {
						if (this.categories[i].getKey() != this.categories[j].getKey()) {
							this.compareCategories(new Pair(this.categories[i].getKey(), selectedOption.getKey()), this.categories[j], changedCategoryPosition);
						}
					}
				}
			}
		} else if (this.categories.length > 0) {
			var category = this.categories[0];

			for (let i = 0; i < category.getOptions().length; i++) {
				var option: Option = category.getOptions()[i];
				var item = null;

				for (let i = 0; i < this.items.length; i++) {
					if (
						this.items[i].getCombinationValue(category.getKey()) == option.getKey()
					) {
						item = this.items[i];
					}
				}

				if (item == null) {
					option.disable();
					option.deselect();;
				}
			}
		}

		/**
		* 
		* Check the selected option from each category
		* and matches it with teh given items
		*/

		/**
		* 
		* Displays the submit button only if alle categories are selected
		*/
		var selectedItem: FilterItem = this.selected(true)[0];
		if (this.submit != null) {
			if (typeof selectedItem !== "undefined") {
				this.submit.removeClass("disabled");
				this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "selected", "true" );
			} else {
				this.submit.addClass("disabled");
				this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "selected", "false" );
			}
		}

		/**
		* 
		* Execute the on change event
		*/
		var selectedItems = this.selected(false);
		if (selectedItems.length > 0) {
			var selectedFilterItemOptions: Array<any> = selectedItems[0].getOptions();

			for (let i = 0; i < selectedFilterItemOptions.length; i++) {
				var outputElements: Array<JQuery<HTMLElement>> = this.findOutputElements("option_" + selectedFilterItemOptions[i].key);
				for (let j = 0; j < outputElements.length; j++) {
					var outputElement: JQuery<HTMLElement> = outputElements[j]
					if (outputElement != null) {
						var type = this.getOutputOption(outputElement, "type");
						if (type != null) {

							if (type == "content") {
								outputElement.html(selectedFilterItemOptions[i].value);
							} else {
								outputElement.attr(type, selectedFilterItemOptions[i].value);
							}

						}
					}
				}
			}

			modules.callGlobalEventFunctionWithArgs("filterItemSelected", false, selectedFilterItemOptions);
		}
	}

	/**
	* 
	* @param first key: current_category_position, value: current_category_selection
	* @param second second category for the comparison
	*/
	private compareCategories(first: Pair<number, string>, compareWith: Category, changedCategoryPosition: number): void {
		for (let i = 0; i < compareWith.getOptions().length; i++) {

			var selectedOption = compareWith.getSelected();
			var item: FilterItem = this.findItem(first, new Pair(compareWith.getKey(), compareWith.getOptions()[i].getKey()));

			var disbaled: boolean = item == null;
			var selected: boolean = selectedOption == compareWith.getOptions()[i];

			if (disbaled) {
				if (selected) {
					if (changedCategoryPosition != compareWith.getKey()) {
						var availableOption = this.findAvailableOption(compareWith);
						if (availableOption != null) {

							compareWith.getOptions()[i].setSelected(false)
							availableOption.setSelected(true);

							this.processAll();
						}
					}
				}

				compareWith.getOptions()[i].disable();
			} else {
				compareWith.getOptions()[i].enable();
			}
		}
	}

	private findAvailableOption(category: Category): Option {
		var result = null;

		/**
		* 
		* Find a available option
		*/
		for (let i = 0; i < category.getOptions().length; i++) {
			if (!category.getOptions()[i].isDisabled() && !category.getOptions()[i].isSelected()) {
				result = category.getOptions()[i];

				/**
				* 
				* Check if the selected options matches with all other categories
				*/
				for (let i = 0; i < this.categories.length; i++) {
					if (this.categories[i].getKey() != category.getKey()) {
						var selectedOption = this.categories[i].getSelected();
						if (selectedOption != null) {
							var filterItem: FilterItem = this.findItem(new Pair(category.getKey(), result.getKey()), new Pair(this.categories[i].getKey(), selectedOption.getKey()));
							if (filterItem == null) {
								result = null;
							}
						}
					}
				}

				if (result != null) {
					break;
				}
			}
		}

		return result;
	}

	/**
	* 
	* @param first key: first_position, value: first_key
	* @param second key: second_position, value: second_key
	* @description check if the required combination exsits
	*/
	private findItem(first: Pair<any, string>, second: Pair<any, string>): FilterItem {
		var result: FilterItem = null;

		for (let i = 0; i < this.items.length; i++) {
			if (
				this.items[i].getCombinationValue(first.getKey()) == first.getValue() &&
				this.items[i].getCombinationValue(second.getKey()) == second.getValue()
			) {
				result = this.items[i];
			}
		}

		return result;
	}

	/**
	* 
	* Handle categories
	*/
	private loadCategories(): void {
		var categoriesOnChangeString = this.getParam("category-change");
		var categoriesOnChange: Array<Pair<number, Pair<string, string>>> = new Array();

		if (categoriesOnChangeString != null) {
			var categoriesOnChangeArray: Array<string> = Strings.toArray(categoriesOnChangeString);
			for (let i = 0; i < categoriesOnChangeArray.length; i++) {
				categoriesOnChange.push(Strings.toPairWithIndex(categoriesOnChangeArray[i]));
			}
		}

		for (let i = 0; i < this.items.length; i++) {
			for (let j = 0; j < this.formatPositions.length; j++) {

				var combinationValue = this.items[i].getCombinationValue(this.formatPositions[j]);

				if (typeof combinationValue !== "undefined") {
					var position = this.formatPositions[j];
					var key: string = combinationValue;

					var category = this.findCategory(position);
					var option = new Option(key);

					if (category == null) {
						/**
						* 
						* set on category change events
						*/
						var categoryOnChange: Pair<string, string> = null;
						for (let k = 0; k < categoriesOnChange.length; k++) {
							if (categoriesOnChange[k].getKey() == position) {
								categoryOnChange = categoriesOnChange[k].getValue();
								break;
							}
						}

						/**
						* 
						* Create new category
						*/
						var newCategory: Category = new Category(position, this.findOutputElement(String(position)), categoryOnChange);
						newCategory.getOptions().push(option);

						this.categories.push(newCategory);
					} else {
						/**
						* 
						* Add the option to an existing category
						*/
						if (!category.optionExists(key)) {
							category.getOptions().push(option);
						}
					}
				}
			}
		}
	}

	private orderCategoryOptions(): void {
		for (let i = 0; i < this.categories.length; i++) {
			var outputElement: JQuery<HTMLElement> = this.categories[i].getOutputElement()
			if (outputElement != null) {
				var order: string = outputElement.attr(Globals.ATTRIBUTE_PREFIX + "order");
				if (typeof order !== "undefined") {

					var orderElements = Strings.toArray(order, false);
					this.categories[i].orderOptions(orderElements, ControllerFilter.OUTPUT_ESCAPING);

				} else {
					this.categories[i].orderOptions(null, ControllerFilter.OUTPUT_ESCAPING);
				}
			}
		}
	}

	private buildCategoryOptions() {
		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].getOutputElement() != null) {
				var splitKey = this.categories[i].getSplitKey(ControllerFilter.OUTPUT_ESCAPING);

				var customOutputName = "output_items." + this.categories[i].getKey();
				var customOutputItem = this.getModule().getComponent(customOutputName);

				for (let j = 0; j < this.categories[i].getOptions().length; j++) {
					var key: string = this.categories[i].getOptions()[j].getKey();

					if (customOutputItem == null) {
						var item: JQuery<HTMLElement> = jQuery(ControllerFilter.OUTPUT_ITEM);

						this.categories[i].getOutputElement().append(item);
						this.categories[i].getOptions()[j].setElement(item);
						this.categories[i].getOptions()[j].getElement().html(this.buildCategoryOptionOutputName(key, splitKey));
						this.categories[i].getOptions()[j].getElement().attr(Globals.ATTRIBUTE_PREFIX + "content", key);
					} else {
						var modelID = this.getModel().new();

						this.getModule().addView(customOutputName, customOutputItem);
						this.getModel().add(modelID, "key", key);

						var item: JQuery<HTMLElement> = jQuery(this.process(modelID, customOutputName));
						if (item.length) {
							this.categories[i].getOutputElement().append(item);
							this.categories[i].getOptions()[j].setElement(item);
						}
					}
				}
			}
		}
	}

	private removesUnelectableItems(): void {
		for (let i = 0; i < this.items.length; i++) {
			if (!this.items[i].isSelectable()) {
				Arrays.removeByIndex(this.items, i);
				i--;
			}
		}
	}

	private buildCategoryOptionOutputName(optionKey: string, splitKey: string): string {
		var result = optionKey;

		for (let i = 0; i < ControllerFilter.OUTPUT_CUT_KEYS.length; i++) {
			if (result.indexOf(ControllerFilter.OUTPUT_CUT_KEYS[i]) > -1) {
				var splittedKeyItems = optionKey.split(splitKey);

				if (splittedKeyItems.length == 2) {
					result = splittedKeyItems[1];
				}
			}
		}

		for (let i = 0; i < ControllerFilter.OUTPUT_ESCAPING.length; i++) {
			result = Strings.replaceMultiple(result, ControllerFilter.OUTPUT_ESCAPING[i], ControllerFilter.OUTPUT_ESCAPING_WITH);
		}

		return result;
	}

	private findCategory(position: number): Category {
		var category: Category = null;

		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].getKey() == position) {
				category = this.categories[i];
				break;
			}
		}

		return category;
	}
}