import { Controller } from "./../../classes/mvc/Controller";
import { Module } from "./../../classes/mvc/Module";
import { ModelRegister } from "./ModelRegister";
import { ModuleRegister } from "./ModuleRegister";
import jQuery = require( "jquery" );

export class ControllerRegister extends Controller<ModelRegister>{

    public constructor ( accessName:string, accessID:number ){
        super( new ModelRegister(), accessName, accessID );
    }
    
    public initGlobals ():void{

    }
	
    public run ():void{

    }
}