import jQuery = require( "jquery" );

export class Number {

	public static plusPercent ( number:number, percent:number ):number{
		return number + ( ( number / 100 ) * percent );
	}
	public static minusPercent ( number:number, percent:number ):number{
		return number - ( ( number / 100 ) * percent );
	}

}