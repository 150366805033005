import { ModelCloudPictures } from "./ModelCloudPictures";
import { ControllerCloudPictures } from "./ControllerCloudPictures";
import { Module } from "../../classes/mvc/Module";

export class ModuleCloudPictures extends Module<ModelCloudPictures> {

    public constructor (configuration:Object) {
        super( "CloudPictures", configuration );

        this.addController( new ControllerCloudPictures( this.getName(), this.getAccessID() ) );
    }

    public run() {
        this.runAllControllers();
    }

    public onControllersInitialized():void{

    }
}