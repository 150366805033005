import { ControllerSelectionFilter } from "./ControllerSelectionFilter";
import { ModelSelectionFilter } from "./ModelSelectionFilter";
import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import jQuery = require( "jquery" );
import { modules } from "../../main";
import { ControllerNavigationSync } from "../NavigationSync/ControllerNavigationSync";

export class ModuleSelectionFilter extends HTMLModule<ModelSelectionFilter>{
        
    public constructor ( configuration:Object ){
        super( "SelectionFilter", configuration );

        this.initAll( ControllerSelectionFilter.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
    }

}