import { Module } from "./../../classes/mvc/Module";
import { ControllerNavigationSync } from "./ControllerNavigationSync";
import { ModelNavigationSync } from "./ModelNavigationSync";
import jQuery = require( "jquery" );

export class ModuleNavigationSync extends Module<ModelNavigationSync> {
    public constructor ( configuration:Object ){
        super( "NavigationSync", configuration );

        this.addController( new ControllerNavigationSync( this.getName(), this.getAccessID() ) );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}