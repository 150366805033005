import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerCaptchaChecker } from "./ControllerCaptchaChecker";
import { ModelCaptchaChecker } from "./ModelCaptchaChecker";
import { IChecker } from "./../../classes/IChecker";
import jQuery = require( "jquery" );

export class ModuleCaptchaChecker extends HTMLModule<ModelCaptchaChecker> implements IChecker{

    public constructor ( configuration:Object ){
        super( "CaptchaChecker", configuration );

        this.initAll( ControllerCaptchaChecker.prototype );
        this.registerCallableMethod( this, "isInputValid", this.isInputValid );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

    public isInputValid ( id:string ):boolean{
        var controller:ControllerCaptchaChecker = this.findController( id ) as ControllerCaptchaChecker;
        if ( controller != null ){
            return controller.isValid();
        } else {
            return false;
        }
    }

}