import { Strings } from "../../libs/Strings";
import Handlebars = require( "handlebars" );

export class Helpers {
    /**
     * 
     * @description register all handlebars helper
     */
    public static init ():void{
        Handlebars.registerHelper ( "includes", helperIncludes);
        Handlebars.registerHelper ( "_if", helperIfBasic);
        Handlebars.registerHelper ("_ifCond", helperConditions);
        Handlebars.registerHelper ( "_if_advanced", helperIfAdvanced);
        Handlebars.registerHelper ( "regex", helperRegex);
        Handlebars.registerHelper ( "starts_with", helperStartsWith);
        Handlebars.registerHelper ( "replace_all", helperReplaceAll);
        Handlebars.registerHelper ( "cut_all", helperCutAll);
        Handlebars.registerHelper ( "cut", helperCut);
        Handlebars.registerHelper ( "cut_after_last", helperCutAfterLast);
        Handlebars.registerHelper ( "check_length", helperCheckLength);
        Handlebars.registerHelper ( "maths", helperMaths);
        Handlebars.registerHelper ( "contains", helperContains );
    } 
}

function helperIncludes ( string: string, array:string, options:any ){
    return Strings.toArray( array ).indexOf( string ) > -1 ? options.fn(this) : options.inverse(this);;
}

/****************************************/
/*****************  IF  *****************/
/****************************************/

 /**
  * 
  * @param paramOne
  * @param operation operator <, >, == or !=
  * @param paramTwo
  * @param options handlebars object
  * @returns the "if" or the "else" content depending on the calculation
  */
function helperIfBasic ( paramOne:any, operation:string, paramTwo:any, options:any ):string {
     return helperIf( paramOne, operation, paramTwo ) ? options.fn(this) : options.inverse(this);
}

/**
  * 
  * @param paramOne
  * @param paramOneByID if true use the input value from the element with the id paramOne
  * @param operation operator <, >, == or !=
  * @param paramTwo
  * @param paramTwoByID if true use the input value from the element with the id paramTwo
  * @param options handlebars object
  * @returns the "if" or the "else" content depending on the calculation
  */
function helperIfAdvanced ( paramOne:any, paramOneByID:string, operation:string, paramTwo:any, paramTwoByID:string, options:any ):string {
    if ( paramOneByID == "true" ){
        paramOne = getInputValue( paramOne );
    }
    if ( paramTwoByID == "true" ){
        paramTwo = getInputValue( paramTwo );
    }
    
    return helperIf( paramOne, operation, paramTwo ) ? options.fn(this) : options.inverse(this);
}

/**
 * 
 * @param string the string to search in
 * @param regex the regular expression
 * @param options handlebars object
 * @returns the "if" or the "else" content depending on the calculation
 */
function helperRegex ( string:string, regex:string, options:any ):string {
    var expression:RegExp = new RegExp( regex );

    if ( expression.exec( string ) ) {
        return options.fn(this);
      } else {
        return options.inverse(this);
    }
}

/**
 * 
 * @param paramOne 
 * @param paramTwo 
 * @param options handlebars object
 * @returns the "if" or the "else" content depending on the calculation
 */
function helperStartsWith ( paramOne:string, paramTwo:string, options:any ):string {
    if ( Strings.startsWith( paramOne, paramTwo ) ) {
        return options.fn(this);
      } else {
        return options.inverse(this);
    }
}

/**
 * 
 * @param value 
 * @param start 
 * @param end 
 * @param options handlebars object
 * @returns the "if" or the "else" content depending on the calculation
 */
function helperCheckLength ( value:string, start:number, end:number, options:any ):string {
    return value.length > start && value.length < end ? options.fn(this) : options.inverse(this);
}

/***************************************************/
/*****************  CUT & REPLACE  *****************/
/***************************************************/

/**
 * 
 * @description replace all "searchFor" strings in the "string" with "replaceWith"
 * @param string string to search in
 * @param searchFor
 * @param replaceWith
 * @returns the replaced string
 */
function helperReplaceAll ( string:string, searchFor:string, replaceWith:string ):string {
    return string.replace( new RegExp( searchFor, "g" ), replaceWith );
}

/**
 * 
 * @description cut only the first "searchFor" strings in the "string"
 * @param string string to search in
 * @param searchFor 
 * @returns the replaced string
 */
function helperCut( string:string, searchFor:string ):string {
    var searchForArray = Strings.toArray( searchFor );
    
    for (let i = 0; i < searchForArray.length; i++) {
        string = string.replace( searchForArray[i], "" );
    }

    return string;
}

/**
 * @description cut all "searchFor" strings in the "string"
 * @param string string to search in
 * @param searchFor
 * @returns the replaced string
 */
function helperCutAll ( string:string, searchFor:string ):string {
    var searchForArray = Strings.toArray( searchFor );
    
    for (let i = 0; i < searchForArray.length; i++) {
        string = string.replace( new RegExp( searchForArray[i], "g" ), "" );
    }

    return string;
}

/**
 * @description cut all chars after the last occurrence of "cutAfter"
 * @example string = HELLO, cutAfter = L, result = HEL
 * @param string string to search in
 * @param cutAfter
 * @returns the replaced string
 */
function helperCutAfterLast ( string:string, cutAfter:string ):string {
    return string.substring( 0, string.lastIndexOf( cutAfter ) );
}

/**
 * 
 * @param numberOne 
 * @param operation operator +, -, * or /
 * @param numberTwo 
 * @param options handlebars object
 * @returns the result of the calculation
 */
function helperMaths ( numberOne:number, operation:string, numberTwo:number, options:any ):string {
    let result = "";
    let number:number = 0;

    switch( operation ){
        case "+":
            number = numberOne + numberTwo;
        break;
        case "-":
            number = numberOne - numberTwo;
        break;
        case "*":
            number = numberOne * numberTwo;
        break;
        case "/":
            number = numberOne / numberTwo;
        break;
        case "^":
            number = Math.pow( numberOne, numberTwo );
        break;
    }

    return number.toString();
}

/**
 * 
 * @param paramOne 
 * @param operation operator <, >, == or !=
 * @param paramTwo
 * @returns the result of the if query
 */
function helperIf ( paramOne:any, operation:string, paramTwo:any):boolean{
    let isTrue:boolean = false;

    if ( operation == "<" || operation == ">" ){
        let numberOne:number;
        let numberTwo:number;

        if( isNaN( paramOne ) ){
            numberOne = paramOne.length;
        } else {
            numberOne = Number( paramOne );
        }

        if( isNaN( paramTwo ) ){
            numberTwo = paramTwo.length;
        } else {
            numberTwo = Number( paramTwo );
        }

        switch ( operation ){
            case ">":
                isTrue = numberOne > numberTwo;
            break;    
            
            case "<":
                isTrue = numberOne < numberTwo;
            break;   
        }
    } else {
        switch ( operation ){
            case "==":
                isTrue = paramOne == paramTwo;
            break;    
            
            case "!=":
                isTrue = paramOne != paramTwo;
            break;    
        }
    }

    return isTrue;
}

/**
 * 
 * @description Check if a input element with the elementID exists and returns the input value
 * @param elementID id of the element
 * @returns the input value
 */
function getInputValue ( elementID:JQuery<HTMLElement> ):string{
    var element:JQuery<HTMLElement> = jQuery( "#" + elementID );
    
    try {
        return element.val() as string;
    } catch (ex){
        return "";
    }
}

function helperContains( paramOne:string, paramTwo:string, options:any ) {
    if ( paramOne.includes(paramTwo) ) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
}

function helperConditions( v1: any , operator: any, v2: any, options: any ) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
}