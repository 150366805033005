import { ModelMenu } from "./ModelMenu";
import { Json } from "./../../libs/Json";
import { modules } from "../../main";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Arrays } from "./../../libs/Arrays";
import jQuery = require( "jquery" );
import { Globals } from "../../classes/Globals";

declare let ezentrum_variables:any;

export class ControllerMenu extends HTMLController<ModelMenu> {

	private static MENU_FILE:Object;

	private static FULL_SEO_LINK:string;

	private position:string;
	private templatename:string;
	private template:Object;

	private fallback:string;

	/**
	 * 
	 * @since 1.5.1
	 */
	private entryPoint:string;

	/**
	 * 
	 * @since 1.6.2
	 */
	private baseLinkPath:string;

	private useAllItems:boolean;

	private menuFileBasePath:string;

	public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement> ){
		super( new ModelMenu(), accessName, accessID, element );
	}

	public initGlobals ():void{
		ControllerMenu.FULL_SEO_LINK = ezentrum_variables.full_seo_link;

		var rootFolder = this.getModule().getConfig("root_folder");
		rootFolder = ( rootFolder == null ? "": rootFolder );

		/**
		 * 
		 * Build the menu file base path
		 */
		this.menuFileBasePath = window.location.origin;

		if ( rootFolder != "" ){
			this.menuFileBasePath += "/" + rootFolder; // test.domain.de/rootFolder/menu-de.json
		} else {
			this.menuFileBasePath += "/menu"; // URL: test.domain.de/menu/menu-de.json
		}

		this.menuFileBasePath += "/menu-";
		
		/**
		 * 
		 * Get the menu file
		 */
		ControllerMenu.MENU_FILE = this.getModel().getMenuFile( this.menuFileBasePath );
    }

	public run ():void{
		if ( ControllerMenu.MENU_FILE != null ){

			this.templatename = this.getParam( "template" );
			if ( this.templatename != null ) {

				this.template = this.getModule().getComponent( "templates." + this.templatename );
				
				this.baseLinkPath = "";

				this.position = this.getParam( "pos" );
				this.fallback = this.getParam( "fallback" );
				this.entryPoint = this.getParam( "entry" );
				this.useAllItems = this.getParam( "use-all" ) == "true";

				if ( this.position == null ){ this.position = ""; }
				if ( this.fallback == null ){ this.fallback = ""; }
				if ( this.entryPoint == null ){ this.entryPoint = ""; }
				if ( this.useAllItems == null ){ this.useAllItems = false; }
		
				if ( typeof ControllerMenu.FULL_SEO_LINK !== "undefined" ) {

					if ( this.template != null ){
						/**
						 * 
						 * Set the entry point
						 */
						var items:Array<Object> = Json.getSubobject( ControllerMenu.MENU_FILE, "elements" );
						if ( this.entryPoint != "" ){

							var entry:Array<Object> = this.findEntryPoint( items, this.entryPoint );
							if( entry != null ){
								this.baseLinkPath = this.entryPoint;
								items = entry;
							}

						}
						
						/**
						 * 
						 * Start processing 
						 */
						this.outputElements( this.processAll( items ) );	

					} else {
						this.getModule().error( Globals.MODULE_LOADING_ERROR + " das folgende Template nicht gefunden wurde: " + this.templatename );
					}

				} else {
					this.getModule().error( Globals.MODULE_LOADING_ERROR + " folgende Variable fehlt: ezentrum_variables.full_seo_link" );
				}

			} else {
				this.getModule().error( Globals.MODULE_LOADING_ERROR + " kein Template angegeben wurde" );
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " keine der folgenden Menüdateien geladen werden konnte: " + this.menuFileBasePath + "[sprachID z.B 1].json oder" + this.menuFileBasePath + "[sprachCode z.B. de].json" );
		}
	}

	private findEntryPoint( items:Array<Object>, path:string ):Array<Object>{
		var result:Array<Object> = null;
		var pathItems:Array<string> = path.split( "/" );

		for (let i = 0; i < pathItems.length; i++) {
			for (let j = 0; j < items.length; j++) {
				if( Json.getSubobject( items[j], "body.link" ) == pathItems[i] ){

					if( i == pathItems.length - 1 ){
						result = Json.getSubobject( items[j], "sub_elements" );
					} else {
						result = this.findEntryPoint( Json.getSubobject( items[j], "sub_elements" ), Arrays.removeByIndex( pathItems, i ).join("/") );
					}
		
					break;
				}
			}

			break;
		}
			
		return result;	
	}

	private outputElements ( content:string ):void{
		var element:JQuery<HTMLElement> = content != "" ? jQuery( content ) : jQuery( this.fallback );

		if( element.length ){
			if ( !this.setOutputElement( element ) ){
				this.getElement().append( element );
			}
		}
	}

	private processAll ( elements:Array<Object> ):string{
		var result = null

		if ( elements != null ) {
			this.getModule().addView( "main_container", Json.getSubobject( this.template, "main_container" ) ) 
			
			result = this.processOne( "main_container", "elements", this.processLevel( elements ) );

			this.getModule().clearViews();
		}

		return result;
	}

	private processLevel ( elements:Array<Object>, lastlink:string = "", is_subelement:boolean = false ):string{
		var result:string = "";
		
		if ( elements != null ) {

			for (var i = 0; i < elements.length; i++) {
				var modelID = this.getModel().new();

				var last_link = lastlink;
				if ( !is_subelement ) { last_link = ""; }

				var element = elements[i];
				var allowed_positions:Array<string> = Json.getSubobject( element, "head.positions" );

				if ( allowed_positions.indexOf( this.position ) >= 0 || this.useAllItems ){

					var currentFullLink:string = "";
					var current_template:string = "";

					var level:number = Json.getSubobject( element, "head.level" );
					var sbid:number = Json.getSubobject( element, "body.sbid" );

					var link:string = Json.getSubobject( element, "body.link" );
					
					var all_subelements:Array<Object> = Json.getSubobject( element, "sub_elements" );
					var allowed_subelements:Array<Object> = new Array();

					/**
					 * 
					 * Check use all flag
					 */
					if( this.useAllItems ){
						allowed_subelements = all_subelements;
					} else {

						/**
						 * 
						 * Get all allowed sub items
						 */
						for (var j = 0; j < all_subelements.length; j++) {
							var tmp_allowed_positions:Array<string> = Json.getSubobject( all_subelements[j], "head.positions" );
							
							if ( tmp_allowed_positions.indexOf( this.position ) >= 0 ){
								allowed_subelements.push( all_subelements[j] );
							}
						}

					}
					
					if ( allowed_subelements.length > 0 ) {
						/**
						 * 
						 * Process item with sub elements
						 */
						last_link += ( last_link == "" ? "" : "/" ) + link;

						var currentContainer:string = Json.getSubobject( this.template, "containers." + level );
						if ( currentContainer != null ){
							current_template = "current_container_" + level;

							this.getModule().addView( current_template, currentContainer );
							this.getModel().add( modelID, "elements", this.processLevel( allowed_subelements, last_link, true ) );
						}
						
						currentFullLink = last_link;
					} else {
						/**
						 * 
						 * Process single item
						 */
						var currentRow:string = Json.getSubobject( this.template, "rows." + level );
						if ( currentRow != null ){
							current_template = "current_row_" + level;
							this.getModule().addView( current_template, currentRow );
						}

						currentFullLink = last_link + ( last_link == "" ? "" : "/" ) + link;
					}

					if ( this.baseLinkPath != "" ){
						currentFullLink = this.baseLinkPath + "/" + currentFullLink;
					}

					if ( current_template != "" ){

						/**
						 * 
						 * Check if the current item is active
						 */
						var sameValueCount:number = 0;
						var fullSEOLinkItems:Array<string> = ControllerMenu.FULL_SEO_LINK.split( "/" );
						var currentFullLinkItems:Array<string> = currentFullLink.split( "/" );

						for (let j = 0; j < currentFullLinkItems.length; j++) {
							if ( fullSEOLinkItems[j] !== undefined ){
								if ( fullSEOLinkItems[j] == currentFullLinkItems[j] ){
									sameValueCount++;
								}
							}
						}

						var active:boolean = sameValueCount == currentFullLinkItems.length;

						/**
						 * 
						 * All all global items to the model
						 */
						this.getModel().add( modelID, "head", Json.getSubobject( element, "head" ) );
						this.getModel().add( modelID, "body", Json.getSubobject( element, "body" ) );

						this.getModel().add( modelID, "active", active );

						this.getModel().add( modelID, "children", allowed_subelements.length );
						
						this.getModel().add( modelID, "first_item", i == 0);
						this.getModel().add( modelID, "last_item", i == elements.length - 1);
						this.getModel().add( modelID, "count", i);

						this.getModel().add( modelID, "curr_link", ControllerMenu.FULL_SEO_LINK );

						this.getModel().add( modelID, "language", modules.getLanguageCode());

						if ( sbid == 0 ){
							this.getModel().add( modelID, "curr_item_link", link );
						} else {
							this.getModel().add( modelID, "curr_item_link", currentFullLink );
						}

						/**
						 * 
						 * Process Handlebars
						 */
						result += this.process( modelID, current_template, true );
					}
				}
			}

		}

		return result;
	}

}