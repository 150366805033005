import { Module } from "./../../classes/mvc/Module";
import { ModelCallNumber } from "./ModelCallNumber";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import jQuery = require( "jquery" );

export class ControllerCallNumber extends HTMLController<ModelCallNumber>{

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>){
        super( new ModelCallNumber(), accessName, accessID, element );
    }

    public initGlobals ():void{
        
    }

    public run ():void{
        var number = this.getParam( "number" );
        if ( number != null ){

            this.getElement().replaceWith( "<a href='tel:"+ number +"'>"+ this.getElement().html() +"</a>" );

        }
    }

}